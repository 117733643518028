import * as React from "react";
import {navigate} from "gatsby";
import {useEffect} from "react";

export default () => {
  useEffect(() => {
    navigate("/personal/workbench")
  }, [])
  return (<>
  </>)
}
